@keyframes drawBorder {
  to {
      stroke-dashoffset: 0; /* Reveal the border */
  }
}

.authorBorder_svg__border-path {
  fill: none;
  stroke: url(#authorBorder_svg__a);
  stroke-width: 4;
  stroke-dasharray: 1069;
  stroke-dashoffset: 1069;
}

[data-animation="true"] .authorBorder_svg__border-path {
  animation: drawBorder 8s ease-in-out forwards infinite;
}

[data-is-paused="true"] .authorBorder_svg__border-path {
  animation-play-state: paused;
}
